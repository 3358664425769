import request from '@/utils/request'


// 查询用户动态点赞列表
export function listDynamicLike(query) {
  return request({
    url: '/user/user-dynamic-like/list',
    method: 'get',
    params: query
  })
}

// 查询用户动态点赞分页
export function pageDynamicLike(query) {
  return request({
    url: '/user/user-dynamic-like/page',
    method: 'get',
    params: query
  })
}

// 查询用户动态点赞详细
export function getDynamicLike(data) {
  return request({
    url: '/user/user-dynamic-like/detail',
    method: 'get',
    params: data
  })
}

// 新增用户动态点赞
export function addDynamicLike(data) {
  return request({
    url: '/user/user-dynamic-like/add',
    method: 'post',
    data: data
  })
}

// 修改用户动态点赞
export function updateDynamicLike(data) {
  return request({
    url: '/user/user-dynamic-like/edit',
    method: 'post',
    data: data
  })
}

// 删除用户动态点赞
export function delDynamicLike(data) {
  return request({
    url: '/user/user-dynamic-like/delete',
    method: 'post',
    data: data
  })
}
